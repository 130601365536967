import React from 'react';
import Modal from 'react-modal';
import { ImCross } from 'react-icons/im';
import ContactForm from '../reusable/ContactForm';

const HomeModal = ({ isOpen, closeModal }) =>
{
    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', 
            zIndex: 10000, 
        },
        content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            height: '490px',
            width: '50%',
            zIndex: 10001, 
            backdropFilter: 'blur(20px)', 
            backgroundColor: '#eff1f5', 
            border: 0,  
        },
    };

    return (
        <div className="homeModal">
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                contentLabel="HomeModal"
                style={customStyles}
            >
                <div style={{ textAlign: 'end' }}> </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h4>Email Us</h4>
                    <ImCross onClick={closeModal} style={{ cursor: 'pointer' }} />
                </div>
                <ContactForm />
            </Modal>
        </div>
    );
};

export default HomeModal;
