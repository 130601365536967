import project1 from "../../assest/images/bmbc.png";
import projectrct2 from "../../assest/images/ept.png";
import projectrct3 from "../../assest/images/omgs.png";
import project2 from "../../assest/images/uch.png";
import project3 from "../../assest/images/chf.png";
import projectphp2 from "../../assest/images/enrol.png";
import projectphp3 from "../../assest/images/rpl.png";
import project4 from "../../assest/images/podel.png";
import web1 from "../../assest/images/euro.png";
import web2 from "../../assest/images/estate.png";
import web3 from "../../assest/images/out.png";
import web4 from "../../assest/images/play.png";
import web5 from "../../assest/images/hema.png";
import web6 from "../../assest/images/beauty.png";
import web7 from "../../assest/images/jamila.png";
import web8 from "../../assest/images/mod.png";
import web9 from "../../assest/images/prog.png";
import web10 from "../../assest/images/fashion.png";
import web11 from "../../assest/images/long.png";
import web12 from "../../assest/images/suv.png";
import web13 from "../../assest/images/mpchess.png";
import web14 from "../../assest/images/back.png";
import web15 from "../../assest/images/byja.png";
import web16 from "../../assest/images/canu.png";
import web17 from "../../assest/images/aqclabs.png";
import web18 from "../../assest/images/eduv.png";
import web19 from "../../assest/images/central.png";
import web20 from "../../assest/images/avi.png";
import web21 from "../../assest/images/mahesh.png";
import showCaseImage from "../../assest/images/showCaseImage.png";
const projectArray = [
    {
        id: 1,
        imageUrl: project1,
        imageUrl2: projectrct2,
        imageUrl3: projectrct3,
        title: "React Project",
        icon: showCaseImage,
        // detail: "Welcome to our DevOps Services! We offer comprehensive DevOps solutions to help businesses harness......",
        incldet: "Here's what our service includes",
        incldescription: [
            {
                id: 1,
                detail: "We Provide SEO freindly web-app"
            },
            {
                id: 2,
                detail: "We gave client satisfaction"
            },
            {
                id: 3,
                detail: "We design , we create , we develop"
            }
        ],
        head: "Web App Services",
        hdpara: "This project is a showcase of my expertise in building dynamic web applications using React.js, Node.js, and Express.js. I utilized React Bootstrap for a sleek and responsive UI design, and Redux for state management. The result is a feature-rich application that demonstrates my skills in front-end development, back-end integration, and state management. I'm proud of the seamless user experience and robust functionality this project delivers.",
        link: "bookmybestclass.com",
        link2: "exampreptool.com",
        link3: "omgs.in",
        prodetail:"Online Course Management Systems",
        prodetail2:"Online Training Management Systems ",
        prodetail3: "PhotoFrame seller E-Commerce Website",
        indeProjects: [
            {
                id: 1,
                prodet: "Online Course Management Systems",
                imageUrl: project1,
                link: "bookmybestclass.com/",
                
            },
            {
                id: 2,
                prodet: "Online Training Management Systems ",
                imageUrl: projectrct2,
                link:"exampreptool.com/",
                
            },
            {
                id: 3,
                prodet: " PhotoFrame seller E-Commerce Website ",
                imageUrl: projectrct3,
                link:"omgs.in"
            }
        ],
    },
    {
        id: 2,
        imageUrl: project2,
        title: "Web Design Project",
        icon: showCaseImage,
        detail: "Welcome to our DevOps Services! We offer comprehensive DevOps solutions to help businesses harness......",
        incldet: "Here's what our service includes",
        incldescription: [
            {
                id: 1,
                detail: "We Provide SEO freindly web-app"
            },  
            {
                id: 2,
                detail: "We gave client satisfaction"
            },
            {
                id: 3,
                detail: "We design , we create , we develop"
            }
        ],
        head: "Website Design",
        hdpara: " Welcome to our DevOps Services! We offer comprehensive cloud solutions to help businesses harness the power of cloud computing and optimize their operations. Our team of cloud experts is committed to delivering secure, scalable, and reliable cloud services.",
        link: "taletykes.com/UCH/",
        indeProjects: [
            {
                id: 1,
                prodet: " taletykes.com/UCH/",
                link: "taletykes.com/UCH/",
                imageUrl: project2 
            },
            {
                id: 2,
                prodet: "eurolabo.eu/ ",
                imageUrl: web1,
                link: "eurolabo.eu/",

            },
            {
                id: 3,
                prodet: "estatebuyers4america.com/",
                imageUrl: web2,
                link:"estatebuyers4america.com/"
            },
            // {
            //     id: 4,
            //     prodet: "bestforeignlanguage.com/",
                // imageUrl: web3,
            //     link:"bestforeignlanguage.com/"
            // },
            {
                id: 5,
                prodet: "outsourcingbuddy.com/",
                imageUrl: web3,
                link: "outsourcingbuddy.com/"
            },
            {
                id: 6,
                prodet: "playeazy.com/",
                imageUrl: web4,
                link: "playeazy.com/"
            },
            {
                id: 7,
                prodet: "hemacollections.in/",
                imageUrl: web5,
                link: "hemacollections.in/"
            },
            {
                id: 8,
                prodet: "beautyandbodylounge.com",
                imageUrl: web6,
                link: "beautyandbodylounge.com"
            },
            {
                id: 9,
                prodet: "jamilahbellydance.com/",
                imageUrl: web7,
                link: "jamilahbellydance.com/"
            },
            {
                id: 10,
                prodet: "modthera.com/",
                imageUrl: web8,
                link: "modthera.com/"
            },
            {
                id: 11,
                prodet: "programs.1specialplace.com/",
                imageUrl: web9,
                link: "programs.1specialplace.com/"
            },
            {
                id: 12,
                prodet: "fashionsalonacademy.com/",
                imageUrl: web10,
                link: "fashionsalonacademy.com/"
            },
            {
                id: 13,
                prodet: "longitudemobility.com/",
                imageUrl: web11,
                link: "longitudemobility.com/"
            },
            {
                id: 14,
                prodet: "suvglobal.eu/",
                imageUrl: web12,
                link: "suvglobal.eu/"
            },
            {
                id: 15,
                prodet: "mpchess.in/",
                imageUrl: web13,
                link: "mpchess.in/"
            },
            {
                id: 16,
                prodet: "backinstride.com/",
                imageUrl: web14,
                link: "backinstride.com/"
            },
            {
                id: 17,
                prodet: "byjacob.ca/",
                imageUrl: web15,
                link: "byjacob.ca/"
            },
            {
                id: 18,
                prodet: "canuckracing.com/",
                imageUrl: web16,
                link: "canuckracing.com/"
            },
            // {
            //     id: 19,
            //     prodet: "cherryminds.com/",
                // imageUrl: web17,
            //     link: "cherryminds.com/"
            // },
            {
                id: 20,
                prodet: "aqclabs.com/",
                imageUrl: web17,
                link: "aqclabs.com/"
            },
            {
                id: 21,
                prodet: "eduvoyageacademy.com/",
                imageUrl: web18,
                link: "eduvoyageacademy.com/"
            },
            {
                id: 22,
                prodet: "centraldatastorage.com",
                imageUrl: web19,
                link: "centraldatastorage.com"
            },
            {
                id: 23,
                prodet: "avidrone.com",
                imageUrl: web20,
                link: "avidrone.com"
            },
            {
                id: 24,
                prodet: "maheshwarimahasabha.org",
                imageUrl: web21,
                link: "maheshwarimahasabha.org"
            },
        ],
       
    },
    {
        id: 3,
        imageUrl: project3,
        imageUrl2: projectphp2,
        imageUrl3: projectphp3,
        title: "PHP Project",
        icon: showCaseImage,
        detail: "Welcome to our DevOps Services! We offer comprehensive DevOps solutions to help businesses harness......",
        incldet: "Here's what our service includes",
        incldescription: [
            {
                id: 1,
                detail: "We Provide SEO freindly web-app"
            },
            {
                id: 2,
                detail: "We gave client satisfaction"
            },
            {
                id: 3,
                detail: "We design , we create , we develop"
            }
        ],
        head: "DevOps Services",
        hdpara: " Welcome to our DevOps Services! We offer comprehensive cloud solutions to help businesses harness the power of cloud computing and optimize their operations. Our team of cloud experts is committed to delivering secure, scalable, and reliable cloud services.",
        link: "childhelp.co.in",
        link2: "enrol.toponsearch.in",
        link3: "www.rplsatta.com",
        indeProjects: [
            {
                id: 1,
                prodet: " ",
                imageUrl: project3,
                link: "childhelp.co.in",

            },
            {
                id: 2,
                prodet: " ",
                imageUrl: projectphp2,
                link: "enrol.toponsearch.in",

            },
            {
                id: 3,
                prodet: " ",
                imageUrl: projectphp3,
                link: "rplsatta.com"
            }
        ],
    },
    {
        id: 4,
        imageUrl: project4,
        title: "Digital Marketing Project",
        icon: showCaseImage,
        detail: "Welcome to our Digital Marketing Services! We provide comprehensive solutions in the realm of digital marketing to assist businesses in maximizing their online presence and achieving...",
        incldet: "Here's what our service includes",
        incldescription: [
            {
                id: 1,
                detail: "We Provide SEO freindly web-app"
            },
            {
                id: 2,
                detail: "We gave client satisfaction"
            },
            {
                id: 3,
                detail: "We design , we create , we develop"
            }
        ],
        head: "DevOps Services",
        hdpara:"Welcome to our Digital Marketing Services! We provide comprehensive solutions to assist businesses in unlocking the potential of online marketing and optimizing their digital presence. Our team of marketing experts is dedicated to delivering effective, scalable, and results-driven digital marketing services.",
        link: "porkishdelight.com/",
        indeProjects: [
            {
                id: 1,
                prodet: " ",
                imageUrl: project4,
                link: "porkishdelight.com/", 
            } 
        ],
    },
]

export default projectArray