import counter1 from "../../assest/images/count1.svg";
import counter2 from "../../assest/images/count2.svg";
import counter3 from "../../assest/images/count3.svg";
import counter4 from "../../assest/images/count4.svg";
const counterArray = [
    {
        id: 1,
        icon: counter1,
        title: "1,000",
        subtitle: "CUP OF COFFEE"
        
    },
    {
        id: 2,
        icon: counter2 ,
        title: "100",
        subtitle: "AWARDS"
    },
    {
        id: 3,
        icon: counter3 ,
        title: "2,000",
        subtitle: "SUCCESSFUL PROJECTS"
    },
    {
        id: 4,
        icon: counter4 ,
        title: "1,000",
        subtitle: "HAPPY CLIENTS"
    }
]
export default counterArray;