import React from 'react'
import HomeRoutes from './Routes/HomeRoutes'
const App = () =>
{
  return (
    <>
      <HomeRoutes />
      
    </>
  )
}

export default App