import React from 'react';
import Layout from '../reusable/Layout';
import Breadcrumb from '../reusable/Breadcrumb';
import termsArray from '../ArrayFiles/termsArray';
import { useParams } from 'react-router-dom';
import ErrorPage from '../../ErrorPage';
// import terms from '../../assest/css/terms.css';

const TermsPage = () =>
{
  const { link } = useParams();

  const matchingTerm = termsArray.find((ele) => link === ele.link);

  if (!matchingTerm)
  {
    return <ErrorPage />;
  }

  return (
    <>
      <Layout>
        <Breadcrumb pageHeader="Our Terms" />
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 full-box'>
              <div className='fir-box'>
                <h1 class="service_title mt-5">{matchingTerm.title}</h1> 
                <p>{matchingTerm.cont}</p>
              </div>
              <div className='sec-box mb-5'>
                <h3 class="service_title">{matchingTerm.head1}</h3>
                <p>{matchingTerm.p1}</p>
                <h3 class="service_title">{matchingTerm.head2}</h3>
                <p>{matchingTerm.p2}</p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default TermsPage;
